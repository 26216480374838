<template>
  <div class="checklist-page">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">{{ checklist.title }}</div>
      </div>
      <div class="wrap">
        <div class="calendar">
          <div class="calendar-head" :class="{'body-open': showBody}">
            <div class="left-arrow" @click="decrease">
              <n-icon icon="arrow-left" />
            </div>
            <div :key="cDate.getTime()" class="title" @click="showBody = !showBody">
              {{ Number($app.date.format(date, 'day')) }} {{ months[cDate.getMonth()] }} {{ cDate.getFullYear() }}
            </div>
            <div class="right-arrow" @click="increase">
              <n-icon icon="arrow-right" />
            </div>
          </div>
          <div v-show="showBody" class="calendar-body">
            <div class="days">
              <div v-for="(weekDay, index) in defDays" class="wrap-day">
                <div class="day">{{ weekDay }}</div>
              </div>
            </div>
            <div class="dates">
              <div v-for="(week, index) in calendar()" :key="index" class="date-row">
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[1]?.numY === getNumberOfDay(cDate)}" @click="select(week[1]?.date)">
                    {{ week[1]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[2]?.numY === getNumberOfDay(cDate)}" @click="select(week[2]?.date)">
                    {{ week[2]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[3]?.numY === getNumberOfDay(cDate)}" @click="select(week[3]?.date)">
                    {{ week[3]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[4]?.numY === getNumberOfDay(cDate)}" @click="select(week[4]?.date)">
                    {{ week[4]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[5]?.numY === getNumberOfDay(cDate)}" @click="select(week[5]?.date)">
                    {{ week[5]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[6]?.numY === getNumberOfDay(cDate)}" @click="select(week[6]?.date)">
                    {{ week[6]?.index }}
                  </div>
                </div>
                <div class="date-wrap">
                  <div class="date" :class="{'selected': week[0]?.numY === getNumberOfDay(cDate)}" @click="select(week[0]?.date)">
                    {{ week[0]?.index }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="types">
        <div v-for="color in types" :key="color.title" class="type">
          <span class="circle" :style="'background-color:'+color.color+';'" />
          <span class="title">{{ color.title }}</span>
        </div>
      </div>
      <div class="title-bar">
        <div class="left">Наименование чекпоинта</div>
        <div class="right">Факт/План</div>
      </div>
    </div>
    <div class="wrap no-margin">
      <c-empty v-if="checkpoints?.length <= 0" title="У вас нет задач на эту дату." />
      <div v-else class="checkpoints">
        <div v-for="item in checkpoints" :key="item.id" class="checkpoint" @click="openId = item.id">
          <div class="checkpoint-row">
            <div class="left">
              <div class="circle" :style="'background-color:'+getColor(item)+';'" />
              <div class="title">{{ item.checkpoint?.title }}</div>
            </div>
            <div class="right">
              <div class="check-count">
                {{ item.checks?.length }}/{{ item.countToCheck }}
              </div>
              <div class="icon" :class="{'opened': openId === item.id}">
                <n-icon icon="backRow" />
              </div>
            </div>
          </div>
          <div v-show="openId === item.id" class="checks">
            <c-empty v-if="item?.checks.length < 1" title="У Вас еще нет посещений в этой задаче." />
            <div v-for="check in item?.checks" :key="check.id" class="check">
              <div class="left">Посещение</div>
              <div class="right">{{ $app.date.format(check.createdAt, 'time') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data() {
    return {
      checklist: {},
      user: $app.auth.user(),
      showBody: false,
      checkpoints: [],
      openId: null,
      types: [
        { title: 'Отмечены все', color: '#64C038', },
        { title: 'Отмечены не все', color: '#A70F61', },
        { title: 'Не отмечено', color: '#FF0000', },
      ],

      date: new Date(),
      cDate: new Date(),
      month: null,
      workDays: $app.auth.user().workDays,
      year: null,
      days: [],
      defDays: [],
      rWeeks: [],
      rDays: [],
      months: [],
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  watch: {
    cDate() {
      if (!this.showBody) {
        this.getCheckpoints()
      }
    },
    date() {
      if (!this.showBody) {
        this.getCheckpoints()
      }
    },
  },
  created() {
    this.load()
    this.init()
  },
  methods: {
    getColor(value) {
      const checkCount = value?.checks.length
      if (checkCount >= value?.countToCheck) {
        return '#64C038'
      }
      if (checkCount < value?.countToCheck && checkCount > 0) {
        return '#A70F61'
      }

      if (checkCount <= 0) {
        return '#FF0000'
      }
      return '#ffffff'
    },
    getCheckpoints() {
      this.$var('load', true)
      const query = 'date='+this.$app.date.format(this.cDate, 'date')
      $api.checklists.stats(this.id, this.user.id).with('checks').with('checkpoint').query(query).then((response) => {
        this.checkpoints = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    load() {
      this.$var('load', true)
      $api.checklists.getOne(this.user.companyId, this.id).then((response) => {
        this.checklist = response.data.content
        this.getCheckpoints()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.defDays = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.cDate.getFullYear(), this.cDate.getMonth() + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay()]: {
            index: i,
            numY: Number(this.getNumberOfDay(new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i))),
            date: new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i),
          },
        }
        if (new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week += 1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    select(date) {
      this.cDate = date
      this.date = this.cDate
      this.showBody = false
    },
    decrease() {
      if (this.showBody) {
        this.date = this.cDate.setMonth(this.cDate.getMonth() - 1)
      } else {
        this.date = this.cDate.setTime(this.cDate.getTime() - (24 * 60 * 60 * 1000))
      }
    },
    increase() {
      if (this.showBody) {
        this.date = this.cDate.setMonth(this.cDate.getMonth() + 1)
      } else {
        this.date = this.cDate.setTime(this.cDate.getTime() + (24 * 60 * 60 * 1000))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: 55px 15px 0 15px;
}

.checklist-page {
  padding-top: 50px;

  .wrap-title {
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    height: 44px;
    right: 0;
    top: calc(env(safe-area-inset-top) + 100px);
  }

  .empty {
    text-align: center;
    font-size: 1.5em;
    color: #ababab;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: #000000;
    font-size: 16px;
  }
  .types {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .type {
      margin-top: 5px;
      display: flex;
      align-items: center;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      .circle {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 14px;
        height: 14px;
        background-color: #64C038;
        margin-right: 5px;
      }

      .title {
        font-weight: 400;
        height: 100%;
        line-height: 1;
        color: #000000;
        font-size: 10px;
        margin-bottom: 0;
      }
    }
  }
  .title-bar {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 12px;
      color: #777676;
      font-weight: 500;
    }
    .right {
      font-size: 12px;
      color: #777676;
      font-weight: 500;
    }
  }
  .wrap {
    margin-top: 44px;
    &.no-margin {
      margin-top: 12px;
    }
    .checkpoint {
      margin-bottom: 12px;
      background-color: #BDBDBD1A;
      min-height: 40px;
      padding: 12px 20px;
      .checkpoint-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .circle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .title {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .check-count {
            color: #000000;
            font-size: 14px;
            font-weight: 500;
          }
          .icon {
            ::v-deep {
              path {
                fill: #2C117C;
              }
            }
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 600ms;
            transform: rotate(90deg);
            margin-left: 15px;
            &.opened {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .checks {
        padding: 10px 30px;
        .check {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left  {
            font-size: 14px;
            color: #000000;
            font-weight: 500;
          }
          .right {
            font-size: 14px;
            color: #000000;
            font-weight: 500;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .calendar {
    position: relative;
    &-head {
      background: var(--primary);
      height: 40px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      border-radius: 10px;
      &.body-open {
        border-radius: 10px 10px 0 0;
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        color: #FFF;
        margin: 0;
      }
    }

    &-body {
      z-index: 100;
      border-radius: 8px;
      position: absolute;
      left: 0;
      right: 0;
      padding: 20px 0;
      background: #FFF;

      .days {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .wrap-day {
          padding: 0 4px;
        }

        .day {
          font-weight: 700;
          font-size: 16px;
          color: #000;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            background-color: #2C117C1A;
          }
        }
      }

      .dates {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 7px;

        .date-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .date-wrap {
          padding: 5px 0;

          .date {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            color: #000;

            &.selected {
              background-color: #2C117C1A;
            }
            &.inactive {
              color: #777676;
            }
          }
        }
      }
    }
  }
}
</style>
